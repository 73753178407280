import { Application } from "@hotwired/stimulus"
import simpleActions from './simpleActions'
import tableSortable from './tableSortable'
const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
