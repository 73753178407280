import usePrototypes from './ThyShallNotUsePrototypes'
usePrototypes();
// relies on thy shall not use prototypes being loaded

document.addDelegatedEventListener("change", ".button.destroy input[type=checkbox]", (e)=>{
  let scope = e.target.name.replace("[_destroy]", "");
  let fieldset = document.querySelector(`fieldset[data-scope="${scope}"]`);

  if (e.target.checked) {
    fieldset.classList.add("destroyed");
    fieldset.querySelectorAll("input, select").forEach(function(input) {
      input.required = false
    })
  } else {
    fieldset.classList.remove("destroyed");
  }
})